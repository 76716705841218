import React from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import { findDOMNode } from "react-dom"

class Toasts extends React.Component {
	renderToast(toast) {
		return (
			<div key={toast.id} className={classNames("toast", toast.visible ? "toast-visible" : "toast-hidden")}>
				<div className="toast-message" dangerouslySetInnerHTML={{ __html: toast.message }}></div>
			</div>
		)
	}

	render() {
		return <div id="toast-container">{this.props.toasts.map((toast) => this.renderToast(toast))}</div>
	}

	checkToastsStates() {
		const container = findDOMNode(this)

		Array.from(container.querySelectorAll(".toast-visible")).forEach((toast) => {
			toast.style.height = `${toast.scrollHeight}px`
			toast.style.opacity = 1
		})

		Array.from(container.querySelectorAll(".toast-hidden")).forEach((toast) => {
			toast.style.height = 0
			toast.style.opacity = 0
		})
	}

	componentDidMount() {
		this.checkToastsStates()
	}

	componentDidUpdate() {
		this.checkToastsStates()
	}
}

const mapStateToProps = (state) => ({
	toasts: state.toast.list,
})

export default connect(mapStateToProps)(Toasts)
