import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import contractNamespace from "../contract/namespace"
import RequestService from "../../services/requestService"
import { isMobile, makeCancelable } from "../../utils"
import ZestyService from "../../services/zesty"
import ConfigService from "../../services/config"
import ContactService from "../../services/contact"
import PaymentService from "../../services/payment"
import { ZestyActions } from "../zesty/actions"

export const SYMPTOM_OTHER = "32"

const actionsBuilder = new ActionsBuilder(namespace)

export const canRequestServiceStart = actionsBuilder.createAction("canRequestServiceStart", (state, loadingPromise) => {
	state[namespace].canRequestService = false
	state[namespace].canRequestServiceLoadingPromise = loadingPromise
})

export const canRequestServiceEnd = actionsBuilder.createAction("canRequestServiceEnd", (state, canRequestService) => {
	state[namespace].canRequestService = canRequestService
	state[namespace].canRequestServiceLoadingPromise = null
})

export const setContract = actionsBuilder.createAction("setContract", (state, contract) => {
	state[namespace].contract = contract
})

export const setSessionUUID = actionsBuilder.createAction("setSessionUUID", (state, sessionUUID) => {
	state[namespace].sessionUUID = sessionUUID
})

export const setSessionExpiration = actionsBuilder.createAction("setSessionExpiration", (state, sessionExp) => {
	state[namespace].sessionExp = sessionExp
})

export const setPurchaseDate = actionsBuilder.createAction("setPurchaseDate", (state, purchaseDate) => {
	state[namespace].purchaseDate = purchaseDate
})

export const setCanDownloadContract = actionsBuilder.createAction("setCanDownloadContract", (state, canDownloadContract) => {
	state[namespace].canDownloadContract = canDownloadContract
})

export const resetState = actionsBuilder.createAction("resetState", (state) => {
	// contract lookup
	state[namespace].sessionUUID = null
	state[namespace].sessionExp = null
	state[namespace].contract = null
	state[namespace].dwellingTypeCode = null

	// pick items page
	if (state[namespace].serviceItemCategoriesLoadingPromise) {
		state[namespace].serviceItemCategoriesLoadingPromise.cancel()
	}

	state[namespace].serviceItemCategories = []
	state[namespace].serviceItemCategoriesLoading = false
	state[namespace].serviceItemCategoriesLoadingPromise = null

	// items details page
	state[namespace].skipItemsDetail = false
	state[namespace].selectedServiceItems = []
	state[namespace].pricing = null

	// payment page
	state[namespace].storedPaymentMethods = null
	state[namespace].defaultContactInfo = null
	state[namespace].contractorOptions = null
	state[namespace].scheduleOptions = null
	state[namespace].contractorOption = null
	state[namespace].scheduleOption = null
	state[namespace].contactInfoPhoneRequired = false // TODO: remove if BDS request flow is active

	// confirmation page
	state[namespace].confirmationDetails = null
})

export const setServiceItemCategoriesLoading = actionsBuilder.createAction("setServiceItemCategoriesLoading", (state, promise) => {
	state[namespace].serviceItemCategories = []
	state[namespace].serviceItemCategoriesLoading = true
	state[namespace].serviceItemCategoriesLoadingPromise = promise
})

export const setServiceItemCategories = actionsBuilder.createAction("setServiceItemCategories", (state, serviceItemCategories) => {
	if (serviceItemCategories) {
		state[namespace].serviceItemCategories = serviceItemCategories
	}
	state[namespace].serviceItemCategoriesError = !serviceItemCategories
	state[namespace].serviceItemCategoriesLoading = false
	state[namespace].serviceItemCategoriesLoadingPromise = null
})

export const setSelectedServiceItems = actionsBuilder.createAction("setSelectedServiceItems", (state, selectedServiceItems) => {
	state[namespace].selectedServiceItems = selectedServiceItems
})

export const setPricing = actionsBuilder.createAction("setPricing", (state, pricing) => {
	state[namespace].pricing = pricing
})

export const setSkipItemsDetail = actionsBuilder.createAction("setSkipItemsDetail", (state, skipItemsDetail) => {
	state[namespace].skipItemsDetail = skipItemsDetail
})

export const setServiceItemOptionsMap = actionsBuilder.createAction("setServiceItemOptionsMap", (state, serviceItemOptionsMap) => {
	state[namespace].serviceItemOptionsMap = serviceItemOptionsMap
})

export const updateSelectedServiceItem = actionsBuilder.createAction("updateSelectedServiceItem", (state, { index, update }) => {
	const selectedServiceItems = state[namespace].selectedServiceItems.slice()

	selectedServiceItems[index] = Object.assign({}, selectedServiceItems[index], update)

	state[namespace].selectedServiceItems = selectedServiceItems
})

export const setStoredPaymentMethods = actionsBuilder.createAction("setStoredPaymentMethods", (state, storedPaymentMethods) => {
	state[namespace].storedPaymentMethods = storedPaymentMethods
})

export const setDefaultContactInfo = actionsBuilder.createAction("setDefaultContactInfo", (state, defaultContactInfo) => {
	state[namespace].defaultContactInfo = defaultContactInfo
})

export const setContractorOptions = actionsBuilder.createAction("setContractorOptions", (state, contractorOptions) => {
	state[namespace].contractorOptions = contractorOptions
})

export const setContractorOption = actionsBuilder.createAction("setContractorOption", (state, contractorOption) => {
	state[namespace].contractorOption = contractorOption
})

export const setScheduleOptions = actionsBuilder.createAction("setScheduleOptions", (state, scheduleOptions) => {
	state[namespace].scheduleOptions = scheduleOptions
})

export const setScheduleOption = actionsBuilder.createAction("setScheduleOption", (state, scheduleOption) => {
	state[namespace].scheduleOption = scheduleOption
})

export const setConfirmationDetails = actionsBuilder.createAction("setConfirmationDetails", (state, confirmationDetails) => {
	state[namespace].confirmationDetails = confirmationDetails
})

export const setRequestService = actionsBuilder.createAction("setRequestService", (state, requestService) => {
	Object.assign(state[namespace], requestService)
})

export const setDwellingType = actionsBuilder.createAction("setDwellingType", (state, dwellingType) => {
	state[namespace].dwellingTypeCode = dwellingType
})

const canServiceItemHaveBrandOptions = (serviceItem) => {
	return serviceItem.tradeCode === ConfigService.config.requestService.tradeCodes.APPLIANCE
}

const canServiceItemHaveSymptomOptions = (serviceItem) => {
	return (
		serviceItem.tradeCode === ConfigService.config.requestService.tradeCodes.AIR_CONDITIONING ||
		serviceItem.tradeCode === ConfigService.config.requestService.tradeCodes.APPLIANCE ||
		serviceItem.tradeCode === ConfigService.config.requestService.tradeCodes.ELECTRICAL ||
		serviceItem.tradeCode === ConfigService.config.requestService.tradeCodes.HEATING ||
		serviceItem.tradeCode === ConfigService.config.requestService.tradeCodes.PLUMBING
	)
}

const isWaterHeaterItem = (serviceItem) => {
	return serviceItem.serviceItemID === ConfigService.config.requestService.serviceItems.WATER_HEATER
}

const fetchServiceItemOptions = async (contractID, sessionUUID, serviceItem) => {
	const getBrands = canServiceItemHaveBrandOptions(serviceItem)
	const getSymptoms = canServiceItemHaveSymptomOptions(serviceItem)
	const getLegacyStyles = isWaterHeaterItem(serviceItem)

	let brands = null,
		symptoms = null,
		legacyStyles = null

	if (getBrands || getSymptoms || getLegacyStyles) {
		const response = await RequestService.getServiceItemOptions(contractID, sessionUUID, serviceItem)

		if (getBrands) {
			brands = response.brands.map(({ ID, name }) => ({ ID, name, legacyBrandID: ID }))
		}
		if (getSymptoms) {
			symptoms = response.symptoms?.sort((a, b) =>
				a.ID === SYMPTOM_OTHER ? 1 : b.ID === SYMPTOM_OTHER ? -1 : a.description.localeCompare(b.description)
			)
		}
		if (getLegacyStyles) {
			legacyStyles = response.legacyStyles
		}
	}

	return { brands, symptoms, legacyStyles, showModelNumber: Boolean(brands?.length) }
}

const handleSetContract = (contract, sessionUUID, sessionExp, purchaseDate, canDownloadContract) => async (dispatch) => {
	dispatch(setContract(contract))
	dispatch(setSessionUUID(sessionUUID))
	dispatch(setSessionExpiration(sessionExp))
	dispatch(setPurchaseDate(purchaseDate))
	dispatch(setCanDownloadContract(canDownloadContract))
}

const handleFetchPricing =
	(overrideRecallRules = false) =>
	async (dispatch, getState) => {
		const state = getState()
		const contractID = state[namespace].contract.contractID
		const { selectedServiceItems, sessionUUID } = state[namespace]

		const pricing = await RequestService.getPricing({
			contractID,
			sessionUUID,
			selectedServiceItems,
			overrideRecallRules,
		})

		if (pricing.recallDispatch) {
			pricing.recallDispatch.serviceItemDescription = selectedServiceItems.find(
				(serviceItem) => serviceItem.serviceItemID === pricing.recallDispatch.serviceItemID
			)?.description
		}

		//Apply prices to selected service items when priced per item
		if (pricing.hasPricesPerItem) {
			selectedServiceItems.forEach((serviceItem) => {
				serviceItem.price = 0
			})

			const selectedServiceItemIndexes = selectedServiceItems.map((_, i) => i)
			pricing.serviceItems.forEach((pricedServiceItem, i) => {
				const selectedServiceItemIndex = selectedServiceItemIndexes.findIndex(
					(index) => selectedServiceItems[index].serviceItemID === pricedServiceItem.serviceItemID
				)
				if (selectedServiceItemIndex >= 0) {
					const [selectedServiceItemIndex] = selectedServiceItemIndexes.splice(selectedServiceItemIndex, 1)
					selectedServiceItems[selectedServiceItemIndex].price = pricedServiceItem.price
				}
			})
		}

		dispatch(setPricing(pricing))
		dispatch(setSelectedServiceItems(selectedServiceItems))
		dispatch(setSessionExpiration(pricing.sessionExpiration))

		return pricing
	}

const fetchStoredPaymentMethods = () => async (dispatch, getState) => {
	const state = getState()

	let storedPaymentMethods = []

	if (state[namespace].isRequestCurrentContract(state) && state[namespace].pricing.collectible) {
		try {
			const paymentInfo = await PaymentService.fetchPaymentInfo(state[namespace].contract.contractID)

			if (paymentInfo.methods) {
				storedPaymentMethods = paymentInfo.methods
			}
		} catch (e) {
			console.error(e)
		}
	}

	dispatch(setStoredPaymentMethods(storedPaymentMethods))
}

const fetchDefaultContactInfo = () => async (dispatch, getState) => {
	const state = getState()

	let defaultContactInfo = null

	if (state[namespace].isRequestCurrentContract(state)) {
		try {
			defaultContactInfo = await ContactService.fetchContactInfo(state[namespace].contract.contractID)
		} catch (e) {
			console.error(e)
		}
	}

	dispatch(setDefaultContactInfo(defaultContactInfo))
}

const fetchContractorOptions = () => async (dispatch) => {
	// TODO: Fetch contractor choice
	// const contractorOptions = await TEMP_GET_CONTRACTOR_OPTIONS()
	// await new Promise((resolve) => setTimeout(resolve, 1500))
	// dispatch(setContractorOptions(contractorOptions))
}

const fetchScheduleOptions = () => async (dispatch, getState) => {
	const state = getState()

	const contractID = state[namespace].contract.contractID
	const selectedItems = state[namespace].selectedServiceItems
	const sessionUUID = state[namespace].sessionUUID
	const dwellingTypeCode = state[namespace].dwellingTypeCode

	const response = await RequestService.fetchScheduleOptions({
		contractID,
		sessionUUID,
		selectedItems,
		dwellingTypeCode,
	})

	dispatch(setScheduleOptions(response.scheduleOptions))
	dispatch(setContractorOptions(response.contractorOptions))
	dispatch(setSessionExpiration(response.sessionExpiration))
}

const handleSubmitItemsDetail = () => async (dispatch) => {
	await Promise.all([
		dispatch(fetchStoredPaymentMethods()),
		dispatch(fetchDefaultContactInfo()),
		dispatch(fetchContractorOptions()),
		dispatch(fetchScheduleOptions()),
	])
}

export const RequestServiceActions = {
	canRequestService: () => async (dispatch, getState) => {
		const state = getState()
		const { current } = state[contractNamespace]
		const { canRequestServiceLoadingPromise: loadingPromise } = state[namespace]

		if (loadingPromise) {
			loadingPromise.cancel()
		}

		if (!current) {
			return
		}

		const cancelablePromise = makeCancelable(RequestService.canRequestService(current.contractID))
		dispatch(canRequestServiceStart(cancelablePromise))

		try {
			const response = await cancelablePromise.promise

			dispatch(canRequestServiceEnd(response.serviceable))
		} catch (e) {
			if (!e.canceled) {
				dispatch(canRequestServiceEnd(false))
			}
		}
	},

	setSessionExpiration: (sessionExp) => async (dispatch) => {
		dispatch(setSessionExpiration(sessionExp))
	},

	setContract: (contract) => async (dispatch) => {
		// First do a lookup and ensure it is eligible
		const response = await RequestService.lookupContract({
			contractID: contract.contractID,
			zipCode: contract.property.zip,
		})

		if (!response.serviceable) {
			return response
		}

		// Then set that it is the contract to be used
		await dispatch(
			handleSetContract(
				contract,
				response.sessionUUID,
				response.sessionExpiration,
				response.purchaseDate,
				response.canDownloadContract
			)
		)
	},

	resetState: resetState,

	lookupContract: (searchOptions) => async (dispatch) => {
		const response = await RequestService.lookupContract(searchOptions)

		if (response.contracts.length === 1 && response.serviceable) {
			await dispatch(
				handleSetContract(
					response.contracts[0],
					response.sessionUUID,
					response.sessionExpiration,
					response.purchaseDate,
					response.canDownloadContract
				)
			)
		} else {
			return response
		}

		return null
	},

	fetchServiceItems: () => async (dispatch, getState) => {
		const state = getState()

		if (state[namespace].serviceItemCategoriesLoadingPromise) {
			state[namespace].serviceItemCategoriesLoadingPromise.cancel()
		}

		dispatch(ZestyActions.getWhatsCoveredPopupConfigs())

		const cancelablePromise = makeCancelable(
			RequestService.fetchServiceItemCategories(state[namespace].contract.contractID, state[namespace].sessionUUID, true)
		)

		dispatch(setServiceItemCategoriesLoading(cancelablePromise))

		try {
			const response = await cancelablePromise.promise

			dispatch(setServiceItemCategories(response.serviceItemCategories))
			dispatch(setSessionExpiration(response.sessionExpiration))
		} catch (e) {
			dispatch(setServiceItemCategories())
			if (!e.canceled) {
				throw e
			}
		}
	},

	setSelectedServiceItems: (serviceItems) => async (dispatch) => {
		const selectedServiceItems = serviceItems.map((serviceItem) => {
			return {
				...serviceItem,
				brand: null,
				modelNumber: null,
				symptom: null,
				symptoms: [],
			}
		})

		// If there are service items with more than 1 unit, then create duplicate items and reset the original unit number to 1
		selectedServiceItems.forEach((serviceItem) => {
			for (let i = 1; i < serviceItem.quantity; i++) {
				selectedServiceItems.push({
					...serviceItem,
					quantity: 1,
				})
			}

			serviceItem.quantity = 1
		})

		selectedServiceItems.sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()))

		dispatch(setSelectedServiceItems(selectedServiceItems))

		return selectedServiceItems
	},

	fetchServiceItemsDetailOptions: () => async (dispatch, getState) => {
		const state = getState()
		const { contract, sessionUUID } = state[namespace]

		let skipItemsDetail = true

		const visitedServiceItems = new Set()
		const uniqueServiceItems = state[namespace].selectedServiceItems.filter((serviceItem) => {
			if (!visitedServiceItems.has(serviceItem.serviceItemID)) {
				visitedServiceItems.add(serviceItem.serviceItemID)
				return true
			}
			return false
		})

		const serviceItemOptionsMap = {}

		await Promise.all(
			uniqueServiceItems.map(async (serviceItem) => {
				const { brands, symptoms, legacyStyles, showModelNumber } = await fetchServiceItemOptions(
					contract.contractID,
					sessionUUID,
					serviceItem
				)

				serviceItemOptionsMap[serviceItem.serviceItemID] = { brands, symptoms, legacyStyles, showModelNumber }

				skipItemsDetail = skipItemsDetail && !brands?.length && !showModelNumber && !symptoms?.length && !legacyStyles?.length
			})
		)

		dispatch(setSkipItemsDetail(skipItemsDetail))
		dispatch(setServiceItemOptionsMap(serviceItemOptionsMap))

		return skipItemsDetail
	},

	updateSelectedServiceItem: (index, update) => updateSelectedServiceItem({ index, update }),

	getPricing: () => async (dispatch) => {
		const pricing = await dispatch(handleFetchPricing())

		return pricing
	},

	getPricingNoRecall: () => async (dispatch) => {
		const pricing = await dispatch(handleFetchPricing(true))

		return pricing
	},

	submitItemsDetail: handleSubmitItemsDetail,

	submitServiceRequest: (data) => async (dispatch, getState) => {
		const state = getState()

		const request = {
			sessionUUID: state[namespace].sessionUUID,
			contactEmail: data.contactEmail,
			contactPhone: data.contactPhone,
			contactPreference: data.contactPreference,
			contractorOption: data.contractorOption,
			scheduleOption: data.scheduleOption,
			contractID: state[namespace].contract.contractID,
			device: isMobile() ? "MOBILE" : "WEB",
			serviceItems: state[namespace].selectedServiceItems,
		}

		if (data.paymentMethod) {
			const { paymentMethodSaved, ...paymentMethod } = data.paymentMethod

			request.paymentMethod = paymentMethod
		}

		const response = await RequestService.submit(request)

		const confirmationDetails = {
			sessionUUID: request.sessionUUID,
			dispatchID: response.dispatchID,
			contractID: request.contractID,
			appointmentTime: request.scheduleOption,
			contactEmail: request.contactEmail,
			contractor: response.contractor,
			contractorOptions: state[namespace].contractorOptions,
			selectedServiceItems: state[namespace].selectedServiceItems,
			pricing: state[namespace].pricing,
			tradeServiceFee: response.tradeServiceFee,
			dispatchMe: response.dispatchMe,
			paymentMethod: data.paymentMethod,
			requestServiceErrors: response.requestServiceErrors,
			interactedWithContractOptionsWidget: data.interactedWithContractOptionsWidget,
			streemPopupType: response.streemPopupType,
		}

		dispatch(setConfirmationDetails(confirmationDetails))

		// in case eligibility changes after submitting a service request
		dispatch(RequestServiceActions.canRequestService())
	},

	setRequestService: setRequestService,

	setSchedule:
		({ selectedContractor, scheduleOption }) =>
		(dispatch) => {
			dispatch(setScheduleOption(scheduleOption))
			dispatch(setContractorOption(selectedContractor))
		},

	setDwellingType: setDwellingType,
}

export const actions = actionsBuilder.exportActions()
