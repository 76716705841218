import classNames from "classnames"
import React from "react"
import { connect } from "react-redux"

import Header from "./Header/Header"
import PageContent from "./PageContent"
import PageOverlay from "./PageOverlay"
import Toasts from "./Toasts"
import Footer from "./Footer/Footer"

import PageHeader from "./PageHeader"

class Page extends React.Component {
	render() {
		return (
			<div className="flex flex-col min-h-screen pt-12 md:pt-0">
				<Header />

				<PageHeader
					title={this.props.title}
					subTitle={this.props.subTitle}
					showAddressSelector={this.props.useAddressSelector}
					breadcrumbs={this.props.breadcrumbs}
					sideContent={this.props.sideContent}
				/>

				<PageContent className={classNames(this.props.className, "flex-1 p-4 md:pt-5")}>{this.props.children}</PageContent>

				<PageOverlay />

				<Toasts />

				{!this.props.removeFooter && <Footer />}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	isLoggedIn: state.user.isLoggedIn,
})

export default connect(mapStateToProps)(Page)
