import GenericService from "./generic"

const SERVICE_NAME = "dispatch"

class DispatchService extends GenericService {
	constructor() {
		super(SERVICE_NAME)

		this.useAccessTokenInAxios()
	}

	getDispatchDetails(dispatchID) {
		return this.axios.get(`/my-account/dispatch/${dispatchID}`)
	}

	cancelDispatchItems(itemsToCancel) {
		return this.axios.post("/my-account/cancel-dispatch", itemsToCancel)
	}

	completeDispatch(dispatchID) {
		return this.axios.post(`/my-account/dispatch/${dispatchID}/complete`)
	}

	fetchDispatchDetailsByContract(contractID, itemPerPage = 0, offsetValue = 0) {
		if (!itemPerPage && !offsetValue) {
			return this.axios.get(`/my-account/dispatch-contract/${contractID}`)
		}
		return this.axios.get(`/my-account/dispatch-contract/${contractID}?limit=${itemPerPage}&offset=${offsetValue}`)
	}
}

export default new DispatchService()
