import { ActionsBuilder } from "../utils"
import AuthService from "../../services/auth"
import ProfileService from "../../services/profile"
import namespace from "./namespace"
import { SetMyAccountCookie, setLoginRedirect } from "../../utils"

import QuiqChatLibrary from "../../lib/quiqchat"

const actionsBuilder = new ActionsBuilder(namespace)

export const login = actionsBuilder.createAction("login", (state, { user, profile, profileError }) => {
	state[namespace].isLoggedIn = true
	state[namespace].user = user
	state[namespace].profile = profile
	state[namespace].profileError = profileError
})

export const logout = actionsBuilder.createAction("logout", (state) => {
	state[namespace].isLoggedIn = false
	state[namespace].user = null
	state[namespace].profile = null
	state[namespace].profileError = false
	state[namespace].passwordIsSaving = false
})

export const passwordIsSaving = actionsBuilder.createAction("passwordIsSaving", (state) => {
	state[namespace].passwordIsSaving = true
})

export const passwordIsSavingComplete = actionsBuilder.createAction("passwordIsSavingComplete", (state) => {
	state[namespace].passwordIsSaving = false
})

export const setProfile = actionsBuilder.createAction("setProfile", (state, profile) => {
	state[namespace].profile = profile
})

export const startUpdatingProfile = actionsBuilder.createAction("startUpdatingProfile", (state) => {
	state[namespace].profileIsSaving = true
})

export const endUpdatingProfile = actionsBuilder.createAction("endUpdatingProfile", (state, profile) => {
	state[namespace].profileIsSaving = false
	state[namespace].profile = profile
})

export const suppressPromo = actionsBuilder.createAction("suppressPromo", (state, { promo, contractID }) => {
	state[namespace].profile.contractCustomer.some((contractCustomer) => {
		if (contractCustomer.contractID === contractID) {
			if (!contractCustomer.suppressedPromos.includes(promo)) {
				contractCustomer.suppressedPromos.push(promo)
			}
			return true
		}
		return false
	})
})

export const UserActions = {
	login: () => async (dispatch) => {
		let user
		try {
			user = await AuthService.getUserInfo()
		} catch (err) {
			// If user info is a 404, it means the logged in user does not exist anymore.
			// So we need to remove their user session and treat them as logged out.
			if (err.isAxiosError && err.response?.status === 404) {
				AuthService.getClient().removeUser()
				return false
			}
			throw err
		}

		let profile = null,
			profileError = false

		try {
			profile = await ProfileService.fetchProfile()
		} catch (e) {
			console.log("Failed to fetch profile")

			profileError = true
		}

		dispatch(login({ user, profile, profileError }))

		if (profile) {
			SetMyAccountCookie(true, profile)
		}

		return Boolean(user) && Boolean(profile)
	},

	logout: () => (dispatch) => {
		setLoginRedirect()

		AuthService.getClient()
			.logout()
			.catch((e) => {
				// Something went wrong using auth client to logout
				// Instead, just change user state to being logged out
				AuthService.getClient().removeUser()

				dispatch(logout())
			})

		//clear the user's chat session
		QuiqChatLibrary.unload(true)
	},

	refreshProfile: () => async (dispatch) => {
		const profile = await ProfileService.fetchProfile()

		SetMyAccountCookie(true, profile)

		dispatch(setProfile(profile))
	},

	saveProfile: (profile) => async (dispatch) => {
		// returns true as a success, false as a failure
		dispatch(startUpdatingProfile())

		let success = false
		try {
			await ProfileService.saveProfile(profile)
			//successfully set the profile, so get it from service
			success = true
		} catch (e) {
			if (e.response && e.response.status === 400) {
				//here is what we need to check
				if (e.response.data.errors?.length > 0) {
					if (e.response.data.errors.some((error) => error.key === "INVALID_PHONE_NUMBER")) {
						dispatch(endUpdatingProfile(profile))
						throw e
					}
				}

				// Try to get the profile again since it exists
				success = true
			}
		}

		profile = null
		if (success) {
			try {
				profile = await ProfileService.fetchProfile()
			} catch (e) {
				// not setting profile will show error to the user
				// intentionally empty
			}
		}

		SetMyAccountCookie(true, profile)

		dispatch(endUpdatingProfile(profile))
		return Boolean(profile)
	},

	updatePassword: (passwordData) => async (dispatch) => {
		dispatch(passwordIsSaving())
		try {
			const response = await ProfileService.resetPassword(passwordData)
			if (response.errors) {
				//App layer returns an array but it's only ever a length of 1 so far
				throw new Error(response.errors[0])
			}
		} catch (e) {
			dispatch(passwordIsSavingComplete())

			throw e
		}
		dispatch(passwordIsSavingComplete())
		return null
	},
}

export const actions = actionsBuilder.exportActions()
