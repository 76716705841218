import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import { Toast } from "./state"
import { sleep } from "../../utils"

const TOAST_ANIMATE_TIME = 200 // time it takes for toast to slide into and out of view
const TOAST_DEFAULT_DISPLAY_TIME = 4000

const actionsBuilder = new ActionsBuilder(namespace)

export const throwToast = actionsBuilder.createAction("throwToast", (state, toast) => {
	// Note: Cannot modify existing array (push). Have to return a new array (concat)
	state[namespace].list = state[namespace].list.concat([toast])
})

export const hideToast = actionsBuilder.createAction("hideToast", (state, id) => {
	// Note: Cannot modify the toast object we have. Have to return a new array with the modified object
	state[namespace].list = state[namespace].list.map((toast) => {
		if (toast.id === id) {
			toast.visible = false
		}
		return toast
	})
})

export const removeToast = actionsBuilder.createAction("removeToast", (state, id) => {
	// Note: Cannot modify existing array (splice). Have to return a new array (filter)
	state[namespace].list = state[namespace].list.filter((x) => x.id !== id)
})

export const ToastActions = {
	throwToast: (message, ms = TOAST_DEFAULT_DISPLAY_TIME) => async (dispatch) => {
		const toast = new Toast(message)

		dispatch(throwToast(toast))

		await sleep(TOAST_ANIMATE_TIME + ms)

		dispatch(hideToast(toast.id))

		await sleep(TOAST_ANIMATE_TIME)

		dispatch(removeToast(toast.id))
	},
}

export const actions = actionsBuilder.exportActions()
