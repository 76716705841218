import GenericService from "./generic"

const SERVICE_NAME = "promos"

class PromoService extends GenericService {
	constructor() {
		super(SERVICE_NAME)

		this.useAccessTokenInAxios()
	}

	suppressPromo(contractID, promo) {
		const body = { promo: promo }
		return this.axios.put(`/my-account/${contractID}/suppress-promos`, body)
	}
}

export default new PromoService()
