import React from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { Switch, withRouter, Route } from "react-router-dom"
import querystring from "querystring"
import Loading from "../pages/Loading"
import UserPageRoute from "./components/UserPageRoute"
import userRoutes from "./routes/user"
import { AlertActions } from "../store/alert/actions"
import { ContractActions } from "../store/contract/actions"
import { OnPageChange } from "../analytics/common.analytics"

const PageRouter = ({ location, history, user, currentContract, removeAllAlerts, setCurrentContract }) => {
	React.useEffect(() => {
		if (window.QSI?.API) {
			window.QSI.API.unload?.()
			window.QSI.API.load?.()
			window.QSI.API.run?.()
		}
	}, [location.pathname])

	React.useEffect(() => {
		OnPageChange(user, currentContract)

		removeAllAlerts()

		// Check for query params except on login since login redirects back with code from Fusion Auth
		if (!location.pathname.startsWith("/login") && location.search.length > 1) {
			const search = querystring.parse(location.search.substr(1))

			// Check if a contract was specified, then set that as current contract
			if (search.contractID) {
				setCurrentContract(search.contractID)
			}

			// Check if proactive chat was specified
			if (search.chat) {
				window.sessionStorage.setItem("proactiveChat", "true")
			}

			// Check if doing request service auto lookup
			if (search.contractID && search.zipCode && location.pathname.startsWith("/request-service")) {
				window.sessionStorage.setItem("RequestServiceAutoLookup", JSON.stringify(search))
			}

			// Check if doing request service logged-in for a specific contract
			if (search.contractID && !search.zipCode && location.pathname.startsWith("/request-service")) {
				window.sessionStorage.setItem("RequestServiceSetContract", search.contractID)
			}

			// Check if doing request service preselect item
			if (search.itemID && location.pathname.startsWith("/request-service")) {
				window.sessionStorage.setItem("RequestServiceSelectItem", search.itemID)
			}

			if (search.otp && location.pathname.includes("/non-covered-charges-estimate")) {
				return
			}

			// Remove the search from the URL
			history.replace(location.pathname + location.hash, location.state)
		}
	}, [location.pathname, currentContract])

	const getRouteKey = (route) => (Array.isArray(route.path) ? route.path[0] : route.path)
	const getRouteProps = ({ meta, component, ...route }) => route

	return (
		<React.Suspense fallback={<Loading />}>
			<Switch>
				{userRoutes.map((route) => (
					<Route key={getRouteKey(route)} {...getRouteProps(route)}>
						<UserPageRoute route={route} />
					</Route>
				))}
			</Switch>
		</React.Suspense>
	)
}

const mapStateToProps = (state) => ({
	user: state.user,
	currentContract: state.contract.current,
})

const mapDispatchToProps = (dispatch) => ({
	removeAllAlerts: () => dispatch(AlertActions.removeAllAlerts()),
	setCurrentContract: (contractID) => dispatch(ContractActions.setCurrentContract(contractID)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PageRouter)
