import { useEffect } from "react"
import SentryLibrary from "../lib/sentry"

const SentryLoader = ({ children }) => {
	useEffect(() => {
		SentryLibrary.initialize()
	}, [])

	return <>{children}</>
}

export default SentryLoader
