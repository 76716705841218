import React from "react"
import { connect } from "react-redux"
import {
	GlobalFooterGroupedLinksRowPreset,
	GlobalFooterMinimalFooterRowPreset,
	IconMail,
	IconMessage,
	IconPhone,
	useAppContext,
	Separator,
	HsaLogoColor,
	Ahs2024LogomarkColor,
} from "@ftdr/blueprint-components-react"
import { getUrlByType, isSiteAHS } from "../../utils"
import ConfigService from "../../services/config"
import { ContractActions } from "../../store/contract/actions"
import { FooterID } from "../../services/homebase"
import { ToastActions } from "../../store/toast/actions"

const MinimumFooter = ({ details }) => {
	return (
		<GlobalFooterMinimalFooterRowPreset
			logoRenderer={getFooterLogo}
			legalText={details.legalText}
			links={details.links}
			facebook={details.social.Facebook}
			twitter={details.social.Twitter}
			youtube={details.social.Youtube}
			pinterest={details.social.Pinterest}
			linkedin={details.social.Linkedin}
		/>
	)
}

const getFooterLogo = () => {
	return isSiteAHS() ? <Ahs2024LogomarkColor /> : <HsaLogoColor />
}

const FooterLinks = ({ linkGroups, alignment, accordionWhenMobileView }) => {
	return (
		<GlobalFooterGroupedLinksRowPreset linkGroups={linkGroups} justify={alignment} accordionWhenMobileView={accordionWhenMobileView} />
	)
}

const Footer = (props) => {
	const { footerContent, currentContract, isDownloadingContract } = props
	const {
		appSettings: { localizedText },
	} = useAppContext()

	const getIcon = (icon) => {
		switch (icon) {
			case "IconMessage":
				return <IconMessage size="22" />
			case "IconMail":
				return <IconMail size="22" />
			case "IconPhone":
				return <IconPhone size="22" />
			default:
				return null
		}
	}

	const isDownloadContractLink = (link) => {
		return link.id === FooterID.downloadContract
	}

	const handleDownloadLinkClick = (e) => {
		e.preventDefault()
		props.downloadContract(currentContract.contractID).catch(() => props.throwToast(localizedText("DOWNLOAD_CONTRACT.ERROR")))
	}

	const clickHandler = {
		[FooterID.downloadContract]: handleDownloadLinkClick,
	}

	const mapLinks = (link) => ({
		children: link.menuText,
		className: "text-1",
		href: getUrlByType(link.type, link.menuLink, ConfigService.config.external),
		...(link.icon ? { startIcon: getIcon(link.icon), className: "text-interactive-700 text-1 font-bold" } : {}),
		disabled: isDownloadContractLink(link) && isDownloadingContract,
		onClick: clickHandler[link.id],
	})

	const filterLinks = (link) => {
		let include = true

		// download contract elements when user is logged out
		if (isDownloadContractLink(link)) {
			include = false
		}

		return include
	}

	const getFooterDetails = (data) => {
		return {
			section1: data.section1.map((item, i) => ({
				title: item.menuText,
				orientation: "vertical",
				links: item.children.filter(filterLinks).map(mapLinks),
				linkType: item.linkType || "normal",
			})),
			section2: {
				legalText: data.section2.legalText,
				social: data.section2.social,
				links: data.section2.links.map(mapLinks),
			},
		}
	}

	const details = footerContent ? getFooterDetails(footerContent) : null

	return (
		details && (
			<div id="page-footer">
				<div className="container mx-auto lg:border-t-1 border-gray-300">
					<FooterLinks alignment="between" accordionWhenMobileView={true} linkGroups={details.section1} />
					<MinimumFooter details={details.section2} />
					<Separator orientation="horizontal" gap="sm" />
				</div>
			</div>
		)
	)
}

const mapStateToProps = (state) => ({
	isDownloadingContract: state.contract.isDownloadingContract,
	footerContent: state.zesty?.footer?.content,
	currentContract: state.contract.current,
})

const mapDispatchToProps = (dispatch) => ({
	throwToast: (message) => dispatch(ToastActions.throwToast(message)),
	downloadContract: (contractID) => dispatch(ContractActions.downloadContract(contractID)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
