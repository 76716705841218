import uuidv4 from "uuid/v4"

export class Toast {
	constructor(message) {
		this.id = uuidv4()
		this.message = message
		this.visible = true
	}
}

Toast.prototype[Symbol.toStringTag] = "Toast"

export default {
	list: [],
}
